import { Menu } from "antd";
import React from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";

const FooterMenu = () => {
  return (
    <Menu>
      <Menu.Item>
        <a href="https://www.instagram.com/sailingandpeople/">
          <FaInstagram size="1.5rem" />
        </a>
      </Menu.Item>
      <Menu.Item>
        <a href="https://www.facebook.com/sailingandpeoplecom/">
          <FaFacebookF size="1.3rem" />
        </a>
      </Menu.Item>
      <Menu.Item>
        <a href="https://www.youtube.com/channel/UC_LQt8r6LuEUj3a6KBKqXaQ">
          <FaYoutube size="1.3rem" />
        </a>
      </Menu.Item>
      <Menu.Item>
        <a href="https://twitter.com/sailing_people/">
          <i
            className="bi bi-twitter-x"
            style={{ fontSize: "1.3em" }}
          ></i>
        </a>
      </Menu.Item>
      <Menu.Item>
        <a href="https://www.linkedin.com/company/sailing-and-people/">
          <FaLinkedinIn size="1.3rem" />
        </a>
      </Menu.Item>
      <Menu.Item>
        <a href="https://wa.me/905300907998">
          <FaWhatsapp size="1.3rem" />
        </a>
      </Menu.Item>
    </Menu>
  );
};

export default FooterMenu;
