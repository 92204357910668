import { Button, Drawer } from "antd";
import sailingAndPeople from "assets/images/tekne_logo.svg";
import Navbar from "components/Navbar/Navbar";
import Logo from "components/UI/Logo/Logo";
import Text from "components/UI/Text/Text";
import { AuthContext } from "context/AuthProvider";
import { LayoutContext } from "context/LayoutProvider";
import useWindowSize from "library/hooks/useWindowSize";
import React, { useContext, useState } from "react";
import { FaUser, FaWhatsapp } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";
import { withRouter } from "react-router-dom";
import Sticky from "react-stickynode";
import AuthMenu from "./AuthMenu";
import HeaderWrapper, { AvatarWrapper, CloseDrawer, LogoArea, MobileNavbar } from "./Header.style";
import MainMenu from "./MainMenu";
import MobileMenu from "./MobileMenu";
import NavbarSearch from "./NavbarSearch";
import ProfileMenu from "./ProfileMenu";
import { motion } from "framer-motion";

const LogoIcon = ({ logo }) => <img style={{ position: "absolute", width: "30px" }} src={logo} alt="" />;

function Whatsapp() {
	return (
		<a href="https://wa.me/905300907998" target="_blank" rel="noopener noreferrer">
			<motion.div
				onClick={() => {}}
				whileHover={{ filter: "brightness(1.4)" }}
				transition={{ ease: [0.43, 0.13, 0.23, 0.96], duration: 0.4 }}
				style={{
					cursor: "pointer",
					display: "flex",
					alignItems: "center",
					background: "#f0f0f0",
					fontSize: "22px",
					fontWeight: "600",
					padding: "15px",
					borderRadius: "50px",
					boxShadow: "0 0 10px rgba(0,0,0,0.5)",
				}}
			>
				<FaWhatsapp color="#25D366" size="2.2rem" />
			</motion.div>
		</a>
	);
}

const MobileAvatar = () => (
	<div
		style={{
			maxWidth: "48px",
			minWidth: "48px",
			maxHeight: "48px",
			minHeight: "48px",
			borderRadius: "50%",
			marginRight: "5px",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			background: "#008489",
			color: "white",
		}}
	>
		<FaUser style={{ width: "50%", height: "50%" }} />
	</div>
);

const PlaceHolderAvatar = () => (
	<div
		style={{
			width: "100%",
			height: "100%",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			background: "#008489",
			color: "white",
		}}
	>
		<FaUser style={{ width: "50%", height: "50%" }} />
	</div>
);

export default withRouter(function Header({ location }) {
	const [{ searchVisibility }] = useContext(LayoutContext);
	const { loggedIn, user } = useContext(AuthContext);
	const { width } = useWindowSize();
	const [state, setState] = useState(false);

	const sidebarHandler = () => {
		setState(!state);
	};

	const headerType = location.pathname === "/" ? "transparent" : location.pathname.includes("confirm-mail") ? "none" : "default";

	const userLogo = user?.image;

	return (
		<HeaderWrapper>
			{headerType !== "none" && (
				<Sticky top={headerType === "transparent" ? -1 : 0} innerZ={10001} activeClass="isHeaderSticky">
					{width > 991 ? (
						<Navbar
							logo={
								<>
									{headerType === "transparent" && <LogoIcon logo={sailingAndPeople} />}
									<Logo withLink linkTo="/" src={sailingAndPeople} title="Sailing & People" />
								</>
							}
							navMenu={<MainMenu />}
							authMenu={<AuthMenu />}
							isLogin={loggedIn}
							avatar={userLogo ? <Logo src={userLogo} /> : <PlaceHolderAvatar />}
							profileMenu={<ProfileMenu avatar={userLogo ? <Logo src={userLogo} /> : <PlaceHolderAvatar />} />}
							headerType={headerType}
							searchComponent={<NavbarSearch />}
							location={location}
							searchVisibility={searchVisibility}
						/>
					) : (
						<MobileNavbar className={headerType}>
							<LogoArea>
								<>
									{headerType === "transparent" && <LogoIcon logo={sailingAndPeople} />}
									<Logo withLink linkTo="/" src={sailingAndPeople} title="Sailing & People" />
								</>
								<NavbarSearch />
							</LogoArea>
							<Button className={`hamburg-btn ${state ? "active" : ""}`} onClick={sidebarHandler}>
								<span />
								<span />
								<span />
							</Button>
							<Drawer
								placement="right"
								closable={false}
								onClose={sidebarHandler}
								width="285px"
								className="mobile-header"
								visible={state}
							>
								<CloseDrawer>
									<button onClick={sidebarHandler}>
										<IoIosClose />
									</button>
								</CloseDrawer>
								{loggedIn ? (
									<AvatarWrapper>
										{userLogo ? <Logo src={userLogo} /> : <MobileAvatar />}
										<Text fontWeight="700" as="h3" content={user?.fullName} />
									</AvatarWrapper>
								) : (
									<AuthMenu className="auth-menu" />
								)}
								<MobileMenu className="main-menu" />
							</Drawer>
						</MobileNavbar>
					)}
				</Sticky>
			)}
			<div style={{ zIndex: 100, position: "fixed", bottom: 20, right: 30 }}>
				<Whatsapp />
			</div>
		</HeaderWrapper>
	);
});
