import React from "react";
import PropTypes from "prop-types";
import FooterWrapper, { MenuWrapper, CopyrightArea, SecondaryFooter } from "./Footer.style";
import { MdEmail, MdPhone } from "react-icons/md";
import {FaWhatsapp } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import languageData from "languageData/languageData";

const Footer = ({ logo, menu, bgSrc, copyright, className, path }) => {
	const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
	const lang = window.localStorage.getItem('sailingandpeoplelang') ? window.localStorage.getItem('sailingandpeoplelang') : "en";
	return (
		<>
			<FooterWrapper id="footer" className={className} bg-img={bgSrc}>
				{logo && logo}
				{menu && <MenuWrapper>{menu}</MenuWrapper>}
				<div
					style={{
						display: "flex",
						alignItems: "center",
						fontWeight: isMobile ? "500" : "800",
						marginTop: "20px",
						marginBottom: "10px",
						fontSize: "16px",
						flexDirection: isMobile ? "column" : "row",
						color: "teal",
					}}
				>
						<div style={{ marginRight: "10px", display: "flex", alignItems: "center", color: "teal" }}>
							{/* <MdPhone size="1.2rem" style={{ marginRight: "5px" }} /> */}
							<FaWhatsapp size="1.2rem" style={{ marginRight: "5px" }} />
							<a href="tel:+90 530 090 79 98" style={{color: 'teal'}}>
							0530 090 79 98
							</a>
						</div>
					{!isMobile && (
						<div
							style={{
								height: "20px",
								width: "2px",
								backgroundColor: "teal",
							}}
						/>
					)}
					<div style={{ marginLeft: "10px", display: "flex", alignItems: "center" }}>
						<MdEmail size="1.2rem" style={{ marginRight: "5px" }} />
						<a style={{ color: "teal" }} href="mailto:info@sailingandpeople.com">
							info@sailingandpeople.com
						</a>
					</div>
				</div>
				<div style={{ marginTop: "15px", color: "gray" }}>{languageData[lang].text16}</div>
				{copyright && <CopyrightArea>{copyright}</CopyrightArea>}
			</FooterWrapper>
			{!!path && <SecondaryFooter />}
		</>
	);
};

Footer.propTypes = {
	className: PropTypes.string,
	logo: PropTypes.element,
	menu: PropTypes.element,
	bgSrc: PropTypes.string,
	copyright: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default Footer;
