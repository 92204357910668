import { loadState, saveState } from "library/helpers/localStorage";
import { getInitialData, postUserCredentials } from "library/services/auth.service";
import { toast } from "library/services/misc.service";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

export const AuthContext = React.createContext();

const AuthProvider = (props) => {
	let history = useHistory();
	const [user, setUser] = useState(loadState("user"));
	const [loggedIn, setLoggedIn] = useState(user?.token ? true : false);

	const signIn = (params) => {
		saveState("user", {});
		postUserCredentials(
			{
				email: params.email,
				password: params.password,
			},
			"Users/Login"
		)
			.then((response) => response.json())
			.then((data) => {
				if (data.success) {
					saveState("user", data.data.userInfo);

					setUser(data.data.userInfo);
					getInitialData("Users/Auth", history, logOut);
					setLoggedIn(true);
					toast("success", data.message);
					history.push(`/`);
				} else if (data.IsErrorOccured) {
					toast("error", data.Error.Message);
				}
			});
	};

	const signUp = (firstName, lastName, email, password, phone, setLoading) => {
		saveState("user", {});
		postUserCredentials(
			{
				firstName: firstName,
				lastName: lastName,
				email: email,
				gsmNumber: phone.trim(),
				password: password,
			},
			"Users"
		).then((response) =>
			response.json().then((data) => {
				if (data.success) {
					toast("success", data.message);
					history.push(`/sign-in`);
					setLoading(false);
				} else {
					toast("error", data.Error.Message);
					setLoading(false);
				}
			})
		);
	};

	const logOut = () => {
		setUser(null);
		setLoggedIn(false);
		saveState("user", {});
	};

	return (
		<AuthContext.Provider
			value={{
				loggedIn,
				logOut,
				signIn,
				signUp,
				user,
			}}
		>
			<>{props.children}</>
		</AuthContext.Provider>
	);
};

export default AuthProvider;
