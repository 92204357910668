import { notification } from "antd";
import languageData from "languageData/languageData";

const API_KEY = "https://api.sailingandpeople.production.cmosteknoloji.com/api/v1/";
const lang = window.localStorage.getItem('sailingandpeoplelang') ? window.localStorage.getItem('sailingandpeoplelang') : "en";

const getData = async (url) => {
	const request = {
		method: "GET",
	};
	return await fetch(API_KEY + url, request);
};

export const getHelpData = async () => {
	let url = "Users/Help";
	return await getData(url);
};

export const toast = (type, message) => {
	notification[type]({
		message: type === "success" ? languageData[lang].text263 : "İşlem sırasında bir hata meydana geldi!",
		description: message,
		placement: "bottomRight",
	});
};
