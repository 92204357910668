import { Menu } from "antd";
import { AuthContext } from "context/AuthProvider";
import useOnClickOutside from "library/hooks/useOnClickOutside";
import React, { useContext, useRef, useState } from "react";
import { BiLogOut } from "react-icons/bi";
import { FaCog } from "react-icons/fa";
import { NavLink, withRouter } from "react-router-dom";
import { AGENT_ACCOUNT_SETTINGS_PAGE } from "settings/constant";
import languageData from "languageData/languageData";

const ProfileMenu = ({ avatar, history }) => {
	const { logOut } = useContext(AuthContext);
	const [state, setState] = useState(false);
	const handleDropdown = () => {
		setState(!state);
	};
	const closeDropdown = () => {
		setState(false);
	};
	const dropdownRef = useRef(null);
	useOnClickOutside(dropdownRef, () => setState(false));
	function handleLogout() {
		logOut();
		history.push("/");
	}

	const lang = window.localStorage.getItem('sailingandpeoplelang') ? window.localStorage.getItem('sailingandpeoplelang') : "en";

	return (
		<div className="avatar-dropdown" ref={dropdownRef}>
			<div className="dropdown-handler" onClick={handleDropdown}>
				{avatar}
			</div>

			<Menu className={`dropdown-menu ${state ? "active" : "hide"}`}>
				{/* <Menu.Item onClick={closeDropdown} >
					<NavLink to={AGENT_PROFILE_PAGE}>View Profile</NavLink>
				</Menu.Item>
                                Maybe add privacy policies here
                */}

				<Menu.Item onClick={closeDropdown}>
					<NavLink to={AGENT_ACCOUNT_SETTINGS_PAGE}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<FaCog style={{ marginRight: "5px", marginLeft: "3px" }} size="0.8rem" />
							{languageData[lang].text153}
						</div>
					</NavLink>
				</Menu.Item>
				<Menu.Item>
					<button onClick={handleLogout}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<BiLogOut style={{ marginRight: "5px" }} size="1rem" />
							{languageData[lang].text151}
						</div>
					</button>
				</Menu.Item>
			</Menu>
		</div>
	);
};

export default withRouter(ProfileMenu);
