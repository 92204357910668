import { Menu } from "antd";
import { AuthContext } from "context/AuthProvider";
import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { AGENT_ACCOUNT_SETTINGS_PAGE, HOME_PAGE, CONCEPT_TRAVELS, LISTING_POSTS_PAGE, HELP_DETAILS, PRICING_PLAN_PAGE } from "settings/constant";
import languageData from "languageData/languageData";

const MobileMenu = ({ className }) => {
	const { loggedIn, logOut } = useContext(AuthContext);

    const lang = window.localStorage.getItem('sailingandpeoplelang') ? window.localStorage.getItem('sailingandpeoplelang') : "en";
	return (
		<Menu className={className}>
			<Menu.Item>
				<NavLink exact to={HOME_PAGE}>
					{languageData[lang].text149}
				</NavLink>
			</Menu.Item>
			{/* <Menu.Item>
				<NavLink to={`${CONCEPT_TRAVELS}`}>{languageData[lang].text116}</NavLink>
			</Menu.Item> */}
			{/* <Menu.Item>
				<NavLink to={LISTING_POSTS_PAGE}>{languageData[lang].text147}</NavLink>
			</Menu.Item> */}
			<Menu.Item>
				<NavLink to={`${LISTING_POSTS_PAGE}?property=Sailing Yacht`} >{languageData[lang].text33}</NavLink>
			</Menu.Item>
			<Menu.Item>
				<NavLink to={`${LISTING_POSTS_PAGE}?property=Catamaran`} >{languageData[lang].text34}</NavLink>
			</Menu.Item>
			<Menu.Item>
				<NavLink to={`${LISTING_POSTS_PAGE}?property=Gulet`} >{languageData[lang].text19}</NavLink>
			</Menu.Item>
			<Menu.Item>
				<NavLink to={`${PRICING_PLAN_PAGE}`}>{languageData[lang].text148}</NavLink>
			</Menu.Item>
			{loggedIn && (
				<Menu.Item>
					<NavLink to={AGENT_ACCOUNT_SETTINGS_PAGE}>{languageData[lang].text150}</NavLink>
				</Menu.Item>
			)}
			{loggedIn && (
				<Menu.Item>
					<button onClick={logOut}>{languageData[lang].text151}</button>
				</Menu.Item>
			)}
		</Menu>
	);
};

export default MobileMenu;
