import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import { setStateToUrl, getStateFromUrl } from "library/helpers/url_handler";
import { LISTING_POSTS_PAGE } from "settings/constant";
import { NavbarSearchWrapper } from "./Header.style";
import { Input } from "antd";
import languageData from "languageData/languageData";

const NavbarSearch = (props) => {
	const [searchValue, setSearchValue] = useState([]);

	const handleOnChange = (event) => {
		if (event.which === "13") {
			event.preventDefault();
			event.stopPropagation();
		}
		setSearchValue(event.target.value);
	};

	const handleSubmit = () => {
		const state = getStateFromUrl(props.location);
		const query = {
			...state,
			searchValue: searchValue,
		};
		const search = setStateToUrl(query);
		props.history.push({
			pathname: LISTING_POSTS_PAGE,
			search: search,
		});
	};

	const handleEnter = (e) => {
		if (e.key === "Enter") {
			handleSubmit();
		}
	};
	useEffect(() => {
		setSearchValue(getStateFromUrl(props.location).searchValue);
	}, [props.location]);

	const lang = window.localStorage.getItem('sailingandpeoplelang') ? window.localStorage.getItem('sailingandpeoplelang') : "en";

	return (
		<NavbarSearchWrapper className="navbar_search">
			<Input
				type="text"
				defaultValue=""
				value={searchValue}
				placeholder={languageData[lang].text152}
				size="large"
				onChange={handleOnChange}
				onKeyPress={(e) => handleEnter(e)}
			/>
			<FiSearch onClick={() => handleSubmit()} />
		</NavbarSearchWrapper>
	);
};

export default withRouter(NavbarSearch);
