import { Menu } from "antd";
import React from "react";
import { NavLink } from "react-router-dom";
import { CONCEPT_TRAVELS, LISTING_POSTS_PAGE, HELP_DETAILS, PRICING_PLAN_PAGE } from "settings/constant";
import './MainMenu.css';
import languageData from "languageData/languageData";

const MainMenu = ({ className }) => {
	const lang = window.localStorage.getItem('sailingandpeoplelang') ? window.localStorage.getItem('sailingandpeoplelang') : "en";
	return (
		<Menu className={className}>
			
			{/* <Menu.Item>
				<NavLink to={`${CONCEPT_TRAVELS}`}>{languageData[language].text116}</NavLink>
			</Menu.Item> */}
			<Menu.Item>
				<NavLink to={`${LISTING_POSTS_PAGE}?property=Sailing Yacht`} >{languageData[lang].text33}</NavLink>
			</Menu.Item>
			<Menu.Item>
				<NavLink to={`${LISTING_POSTS_PAGE}?property=Catamaran`} >{languageData[lang].text34}</NavLink>
			</Menu.Item>
			<Menu.Item>
				<NavLink to={`${LISTING_POSTS_PAGE}?property=Gulet`} >{languageData[lang].text19}</NavLink>
			</Menu.Item>
			<Menu.Item>
				<NavLink to={`${PRICING_PLAN_PAGE}`} >{languageData[lang].text148}</NavLink>
			</Menu.Item>
			<Menu.Item>
				<select className="select-language" defaultValue={lang} style={{ width: '180px', height: '40px', borderRadius: '5px' }} onChange={(e) => {
					window.localStorage.setItem('sailingandpeoplelang', e.target.value)
					window.location.reload()
				}}>
					<option style={{ textAlign: 'center' }} className="languge-option" value='en'>English</option>
					<option style={{ textAlign: 'center' }} className="languge-option" value='tr'>Türkçe</option>
				</select>
			</Menu.Item>
		</Menu>
	);
};

export default MainMenu;
