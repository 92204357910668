import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import theme from "themes/default.theme";
import GlobalStyles from "assets/style/Global.style";
import AuthProvider from "context/AuthProvider";
import Routes from "./router";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
	gtmId: "GTM-5PTNVB2",
};

TagManager.initialize(tagManagerArgs);

const App = () => {
	return (
		<ThemeProvider theme={theme}>
			<>
				<GlobalStyles />
				<BrowserRouter>
					<AuthProvider>
						<Routes />
					</AuthProvider>
				</BrowserRouter>
			</>
		</ThemeProvider>
	);
};

ReactDOM.render(<App />, document.getElementById("root"));
