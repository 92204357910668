import React from "react";
import Logo from "components/UI/Logo/Logo";
import Footers from "components/Footer/Footer";
import LogoImage from "assets/images/tekne_logo.svg";
import FooterMenu from "./FooterMenu";

const Footer = () => {
	return (
		<Footers
			logo={<Logo withLink linkTo="/" src={LogoImage} title="Sailing & People" />}
			menu={<FooterMenu />}
			copyright={`Copyright @ ${new Date().getFullYear()} Cmos Teknoloji`}
		/>
	);
};

export default Footer;
