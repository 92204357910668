import { loadState, saveState } from "library/helpers/localStorage";
import { LOGIN_PAGE } from "settings/constant";

const API_KEY = "https://api.sailingandpeople.production.cmosteknoloji.com/api/v1/";
const lang = window.localStorage.getItem('sailingandpeoplelang') ? window.localStorage.getItem('sailingandpeoplelang') : "en";
const getToken = () => loadState("user")?.token;

const getData = async (url) => {
	const request = {
		method: "GET",
	};
	return await fetch(API_KEY + url, request);
};

const postData = async (url, data) => {
	const request = {
		method: "POST",
		headers: { "Content-type": "application/json" },
		body: JSON.stringify(data),
	};
	return await fetch(API_KEY + url, request);
};

const getAuthData = async (url) => {
	const request = {
		method: "GET",
		headers: { Authorization: "Bearer " + getToken() },
	};
	return await fetch(API_KEY + url, request);
};

export const postUserCredentials = async (data, uri) => {
	const request = {
		method: "POST",
		mode: "cors",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify(data),
	};
	return await fetch(API_KEY + uri, request);
};

export const postRegistration = async (data, uri) => {
	const request = {
		method: "POST",
		mode: "cors",
		headers: { "Content-Type": "application/json" },
		body: JSON.stringify(data),
	};
	return await fetch(API_KEY + uri, request);
};

const getShipData = async (url, id = "") => {
	const request = {
		method: "GET",
		mode: "cors",
		headers: { "Content-Type": "application/json" },
	};
	return await fetch(API_KEY + url + id, request);
};

const postAuthData = async (url) => {
	const request = {
		method: "POST",
		mode: "cors",
		headers: { "Content-Type": "application/json", Authorization: "Bearer " + getToken() },
	};
	return await fetch(API_KEY + url, request);
};

export const getBillingInfo = async () => {
	let url = "Users/Billings";
	return await getAuthData(url);
};

export const getUserReservations = async () => {
	const url = "Users/Reservations/Auth";
	const request = {
		method: "GET",
		headers: { "Content-Type": "application/json", Authorization: "Bearer " + getToken() },
	};
	return await fetch(API_KEY + url, request);
};

export const getInitialData = (url, history, logOut) => {
	postAuthData(url).then((response) => {
		if (response.status === 401) {
			localStorage.removeItem("user");
			logOut();
			history.push(LOGIN_PAGE);
			return;
		}
		response.json().then((data) => {
			if (data.success) {
				saveState("user", { ...data?.data.user, token: getToken() });
			} else {
				saveState("user", {});
			}
		});
	});
};

export const getShipTypes = async () => {
	let url = "Ships/Types?choice=1";
	return await getData(url);
};

export const getShipSubComponents = async () => {
	let url = `Ships/SubComponents?choice=${lang === 'tr' ? 1 : 2}`;
	return await getData(url);
};

export const getShipSummaryItems = async () => {
	let url = `Ships/Summaries?choice=1`;
	return await getData(url);
};

export const getShipTypeReprentations = async () => {
	let url = "Ships/Showcase?choice=1";
	return await getData(url);
};

export const getShipById = (url, setData) => {
	getShipData(url).then((res) =>
		res.json().then((res) => {
			setData((prev) => [...prev, res.data]);
		})
	);
};

export const getShipsByType = async (url) => {
	const request = {
		method: "GET",
		mode: "cors",
		headers: { "Content-Type": "application/json", Authorization: "Bearer " + getToken() },
	};
	return await fetch(API_KEY + url, request);
};

const postReservationData = async (url, payload) => {
	const request = {
		method: "POST",
		mode: "cors",
		headers: { "Content-Type": "application/json", Authorization: "Bearer " + getToken() },
		body: JSON.stringify(payload),
	};
	return await fetch(API_KEY + url, request);
};

export const createReservation = async (url, payload) => {
	return await postReservationData(url, payload);
};

export const getShipAsync = async (id) => {
	const url = `Ships/${id}`;
	const request = {
		method: "GET",
	};
	return await fetch(API_KEY + url, request);
};

export const passwordReset = async (data) => {
	return await postData("Users/Password/Reset", data);
};
