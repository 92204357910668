let languageData = {
  en: {
    text1:
      "Everything is just a click away. Forget standard boat holidays. Stay wherever you want as long as you want.",
    text2:
      "Determine the routes you will go, choose the most suitable boat for you, take advantage of many options so that you do not get bored on the boat. We have designed everything for you, from world cuisine workshop to children's playgrounds.",
    text3:
      "Travel the Aegean coast with comfortable wooden boats; Discover charming resort towns, ancient civilizations and unique natural beauties.",
    text4: "Tursab Number",
    text5: "Experience Aegean cuisine",
    text6: "Discover ancient Anatolian civilizations",
    text7: "Witness the unique natural beauties",
    text8: "Feel the Mediterranean rhythm",
    text9: "International",
    text10:
      "Our experienced holiday consultants are waiting for your questions to assist you 7 days a week.",
    text11: "Wy Us?",
    text12:
      "Our experienced holiday consultants are waiting for your questions to assist you 7 days a week.",
    text13: "Secure Reservation",
    text14: "Correct Pricing Policy",
    text15: "Passionate Service",
    text16: "Sailing & People is a SIESTA YATCILIK A.Ş. brand.",
    text17: (e) => {
      return `Prices start from €${e} Euro`;
    },
    text18: "motoryacht",
    text19: "Gulet",
    text20: "Daily",
    text21: "Weekly",
    text22: "Show details",
    text23: "Sort method",
    text24: "Default Sort…",
    text25: "Cabin",
    text26: "Guest",
    text27: "Clean",
    text28: "Apply",
    text29: "increasing by price",
    text30: "decreasing by price",
    text31: "cancel filters",
    text32: "Boat Type",
    text33: "Sailing Yacht",
    text34: "Catamaran",
    text35: "More",
    text36: "An error occured",
    text37: "Go Back",
    text38: "Edit billing information",
    text39: "Edit Profile",
    text40: "Your reservations",
    text41: "Change Password",
    text42: "The size of the image you are trying to upload is too large!",
    text43: "Your Billing Information",
    text44: "This field is required!",
    text45: "Save changes",
    text46: "Select…",
    text47: "Female",
    text48: "Male",
    text49: "An unexpected error has occurred",
    text50: "Your general information",
    text51: "Name",
    text52: "Surname",
    text53: "Birthday",
    text54: "Gender",
    text55: "Email",
    text56: "Phone Number",
    text57: "The number you enter cannot exceed 16 characters!",
    text58: "The number you enter cannot be less than 10 characters!",
    text59: "where you live",
    text60: "Your Favorite Boats",
    text61: "You do not have a favorite boat in your account.",
    text62: "No Image Found…",
    text63: "Your Reservations",
    text64: "You do not have any reservations.",
    text65:
      "Your reservation has been taken, you will be contacted as soon as possible.",
    text66: "Your reservation is confirmed.",
    text67: "The sale transaction has been completed.",
    text68: "Boat Name",
    text69: "Boat Type",
    text70: "Start",
    text71: "End",
    text72: "Cabin Number",
    text73: "Guest Number",
    text74: "Size",
    text75: "Width",
    text76: "Status",
    text77: "Date",
    text78: "Cabin / Guest Number",
    text79: "metre",
    text80: "The new password must be at least 6 characters!",
    text81: "The new password cannot exceed 20 characters!",
    text82: "Confirmation password must be the same!",
    text83: "Welcome Again",
    text84: "Please login to your account",
    text85: "Don't have an account?",
    text86: "Register Now!",
    text87: "Forgot Password!",
    text88: "Please enter a valid email address!",
    text89: "This field is required!",
    text90: "Password must be at least 6 characters!",
    text91: "Password must not be longer than 20 characters!",
    text92: "Welcome",
    text93: "Enter your e-mail address to recover your account.",
    text94: "This field is required!",
    text95: "Please enter a valid email address!",
    text96: "Send email again",
    text97: "Send email",
    text98: "return to login screen",
    text99: "cookie_policy",
    text100: "Cookie Policy",
    text101: "customer_clarification_text",
    text102: "Customer clarification text",
    text103: "Register",
    text104: "Accept the Cookie Policy",
    text105: "Please enter a valid phone number!",
    text106: "Password",
    text107: "Password does not match!",
    text108:
      "Experience both the pleasure of the sea and the sun and the inner peace of yoga and pilates together with yoga and pilates excursions for companies and private groups, accompanied by expert trainers.",
    text109: "Yoga & Pilates",
    text110: "Cycling and Trekking",
    text111:
      "Experience the pleasure of nature in the heavenly bays with private trekking and bicycle tours in the unique nature of our beaches.",
    text112: "Marriage and Honeymoon",
    text113:
      "To make your wedding ceremonies or honeymoon unforgettable, give us a call about our boat organizations.",
    text114: "Kitchen Workshops",
    text115:
      "Whether it's pastry or Italian cuisine... Develop your skills with our culinary workshops for special groups, accompanied by expert trainers.",
    text116: "Thematic Excursions",
    text117: "Please contact us for your thematic trips.",
    text118: "Yoga and Pilates",
    text119: "Kitchen Workshops",
    text120: "Marriage and Honeymoon",
    text121: "View Details",
    text124: "ENJOY YOUR HOLIDAY, LEAVE THE BEST TO US!",
    text125:
      "You will plan your special route, your meal menus down to the smallest detail, your food shopping together with our travel consultants, and when your holiday comes, you will get on your boat by waving your hand.",
    text126: "REVIEW",
    text127:
      "Sailing & People is your boat rental platform where you can find the right boats on the Turkish coasts, with the most accurate route and the most accurate rental prices.",
    text128: "Right Boat",
    text129: "Right Price",
    text130: "Personal Communication",
    text131: "Freedom and Pleasure at Sea: Sailing Yacht",
    text132: "Blue Cruise Classic : GULET",
    text133: "Sailing and Comfort at Sea: CATAMARAN",
    text135: "Show all",
    text138: "Favorites : GULET",
    text139: "Boat Options",
    text140: "Rent Your Boat & Go Blue!",
    text141: "Search",
    text142: "Our Sample Routes",
    text143: "Please contact us for your special route requests.",
    text144: "Hisaronu Bay",
    text145: "Marmaris-Gocek",
    text146: "Login",
    text147: "All Boats",
    text148: "Frequently Asked Questions",
    text149: "Home",
    text150: "Edit Profile",
    text151: "Logout",
    text152: "Boat Code",
    text153: "Profile Settings",
    text154: "We couldn't find any ships that matched your search criteria…",
    text155: "What is the right boat?",
    text156:
      "They are boats that have been fully and completely maintained and have a tourism operation certificate under the supervision of the Ministry of Tourism.",
    text157: "What is the correct price?",
    text158:
      "These are the prices that you will never be deceived in the meeting of quality and price within market conditions.",
    text159: "What is the correct route?",
    text160:
      "It is the route that allows you to visit the most pleasant coves without tiring you, while making the best use of the sea and the sun, arranged according to the current weather conditions, with its experienced captains, from the first point you set out to sea with your boat.",
    text161: "What is passionate service?",
    text162:
      "Those who charter boats from Sailing&People are considered 'Guests', not 'Customers'. 100% satisfaction of our guests is our main philosophy. That's why we do our job with passion.",
    text163: "What is personal communication?",
    text164:
      "On the Sailing &People platform, you can reach our special travel advisor on the mobile phone line for boat selection, your route, food menus, food shopping and all your special requests. Our experienced personal travel advisor service that we offer you is one of our most important differences from other platforms.",
    text165: "Help Center",
    text166: "Get help with your reservations, account and more.",
    text167: "Gulet",
    text168: "Frequently Asked Questions",
    text169: "There are no frequently asked questions on this subject.",
    text170: "10:00 Meeting on the boat in Turgut Village.",
    text171:
      "Boat introduction and orientation, voyage planning, loading of provisions",
    text172:
      "12:00 Departure from the marina, sailing for an average of 2 hours",
    text173:
      "16:00 Arrival to Kuz Bükü, swimming and dinner (Neighbors Restaurant)",
    text174: "07:00 Morning yoga, swimming and breakfast on the boat",
    text175: "11:00 Departure from Kuzbükü, sailing to the destination bay",
    text176: "Swimming break and lunch in Alarga.",
    text177:
      "16:00 Arrival to Dirsek Bükü. Accommodation in Alarga and dinner on board.",
    text178: "07:00 Morning yoga, swimming and breakfast on the boat",
    text179:
      "13:00 Departure from Dirsek Bükü. Sailing to the destination bay, alarga and lunch on the boat.",
    text180: "17:00 Arrival at Bozburun Yacht Club. Dinner at the Club.",
    text181:
      "07:00 Morning yoga, swimming and breakfast on the boat, hiking and visiting Bozburun Town.",
    text182:
      "13:00 Departure from Bozburun, sailing to Söğüt Bay, alarga and lunch on the boat.",
    text183:
      "17:00Söğüt Koyuna varış. Alargada konaklama ve teknede akşam yemeği.",
    text184: "07:00 Morning yoga, swimming and breakfast on the boat",
    text185:
      "13:00 Departure from Söğüt Bay. Sailing to the destination bay, alarga and lunch on the boat.",
    text186:
      "17:00 Arrival in Bozukkale. Accommodation in Alarga, dinner and live music. (Loryma Club).",
    text187:
      "07:00 Swimming and breakfast on the boat. Hiking (visiting animals; goat, donkey, turtle...)",
    text188:
      "13:00 Departure from Bozukkale Sailing to the target bay, alarga and lunch on the boat.",
    text189:
      "17:00 Arrival at Emel Sayın Bay. Accommodation in Alarga and dinner on board.",
    text190: "07:00 Morning yoga, swimming and breakfast on the boat.",
    text191:
      "12:00 Departure from Emel Sayın Bay. Sailing to Selimiye, Free time in Selimiye.",
    text192: "16:00 Departure from Selimiye",
    text193: "17:00 Turgut Zakkum Arrival at the port. Farewell Cocktail.",
    text194: "Friday",
    text195: "Thursday",
    text196: "Wednesday",
    text197: "Tuesday",
    text198: "Monday",
    text199: "Sunday",
    text200: "Saturday",
    text201: "Bodrum Aegean Routes",
    text202:
      "Click on the locations for promotional videos with videos on the routes.",
    text203: "10:00 Meeting at Netsel Marina",
    text204:
      "Boat introduction and orientation, voyage planning, food loading.",
    text205:
      "12:00 Departure from the marina, sailing for an average of 2 hours",
    text206:
      "16:00 Arrival at Çiftlik Bay, swimming and dinner (Azmak Restaurant)",
    text207: "Arrival to Rodos",
    text208: "07:00 Morning Yoga, swimming and breakfast on the boat",
    text209: "10:00 Departure from Çiftlik, 3 hours sailing on average",
    text210: "Swimming break and lunch in Alarga",
    text211: "16:00 Arrival in Ekincik, dinner (My Marina)",
    text212: "09:00 Departure from Ekincik. Dalyan Kaunos tour (optional)",
    text213:
      "Departure from Dalyan at 16:00, sailing for an average of 2 hours",
    text214: "18:00 Arrival to Baba Island/Dinner in Alarga",
    text215: "10:00 Departure from Baba Island, 3 hours sailing on average",
    text216: "13:00 Göcek Göbün Bay lunch break; rest,swimming",
    text217: "17:00 Accommodation on the boat in Gobun",
    text218:
      "10:00 Departure from Fethiye Turunc Spring, 1.5 hour sailing cruise",
    text219:
      "12:00 alarga/lunch (restaurant available Olive) swimming break at Bedri-Rahmi bay",
    text220: "16:00 Hamam Bay Adaia facility/dinner, entertainment (DJ)",
    text221:
      "10:00 Departure from Adaia, 6 hours on average sailing on a single long day",
    text222: "15:00 Arrival to Ekincik Koyu/alarga, swimming, rest, dinner",
    text223: "09:00 Departure from Ekincik, sailing",
    text224: "12:00 Lunch in Kumlubuk, swimming",
    text225: "14:00 Departure from Kumlubuk to Marmaris",
    text226: "17:00 Netsel Marina Connection, farewell cocktail",
    text227: "Marmaris Rodos Routes",
    text228:
      "Click on the locations for promotional videos with videos on the routes.",
    text229: "Facilities",
    text230: "Price",
    text231: "No price has been specified for this boat.",
    text232: "Width",
    text233: "Type",
    text234: "captain",
    text235: "without captain",
    text236: "person",
    text237: "room",
    text238: "location",
    text239: "Create request",
    text240: "Time interval",
    text241:
      "Trips are scheduled from Saturday to Saturday. You can forward your different requests to your travel advisor.",
    text242: "Capacity",
    text243:
      "The guest and cabin capacity for this boat are indicated on the side. You can also specify your requests for different cabins and number of guests here.",
    text244:
      "You can write your special requests about route, rations and all similar subjects in this field.",
    text245: "Create request",
    text246: "Please login to create a request.",
    text247: "You will not pay now",
    text248: "day",
    text249: "Why Us?",
    text250: "Comment",
    text251: "Leave a comment",
    text252: "Tell people your experience…",
    text253: "Send",
    text254: "Details",
    text255: "Comments",
    text256: "View photos",
    text257: "An error occurred during the operation.",
    text258: "back to home page",
    text259: "Your mail has been confirmed",
    text260: "You can close the page.",
    text261: (firstName, lastName) => {
      return `Dear ${firstName} ${lastName}, your pre-reservation request has been received successfully. Our travel advisor will get back to you as quickly as possible.`;
    },
    text262: "back to home page",
    text263: "Transaction successful!",
    text264: "An error occurred during operation!",
    text265: "Blue Travel With Gulet",
    text266: "You have a question ?",
    text267: "Set Cabin and Guest",
    text268: "Facilities",
    text269: "Are you sure you want to delete the comment?",
    text270: "Country",
    text271: "City",
    text272: "District/Town",
    text273: "Post code",
    text274: "Address",
    text275: "New Password",
    text276: "New password confirmation",
    text277: "Password confirmation",
    text278: "Accept",
    text279: "Welcome to Sailing & People",
    text280: "Please register for your account",
    text281: "Do you already have an account!",
    text282: "Sailboat / Catamaran",
    text283: "Length",
    text284: "Captain Type",
    text285: "No comment found",
    text286: "Point",
    text287: "Your Reservation Request Has Been Created",
    // text288: "To Book",
    text289: "I'm interested with this boat, please contact me",
    text290: "Dates of booking",
    text291: "I'm interested, please contact me",
    text292: "Technicial Specifications",
    text293: "Meter",
    text294: "Notes",
    text295: "The prices quoted above do not include Turkish VAT",
    text296: "The prices quoted above do not include food/beverage",
    text297: "The prices include boat, harbour fees, potable water.",
    text298: "Air conditioner has to be turned off at least 01:00 o'clock at midnight.",
    text299: "Embarkation: 16:00 in the afternoon",
    text300: "Disembarkation : 09:00 in the morning",
    text301: "Food&Beverage:",
    text302: "We can do your grocery/market shopping on your behalf according to the menu to be prepared.",
    text303: "You can make the payment to the market staff with your credit card when you get on the boat.",
    text304: "You can bring your alcoholic beverages with you.",
    text305: "OR; Cost of food is 70 Euro per person / per day (drinks are extra)",
    text306: "OR; Full board is 110 Euro per person / Per day (all local drinks are included)"
  },
  tr: {
    text1:
      "Her şey bir tık ilerde. Standart tekne tatillerini unutun. İstediğiniz yerde dilediğiniz kadar kalın.",
    text2:
      "Gideceğiniz rotaları belirleyin , Size en uygun teknenizi seçin , Teknede canınız sıkılmasın diye bir çok seçeneklerden yararlanın.Dünya mutfağı atölyesinden çocuk oyun alanlarına kadar her şeyi sizler için dizayn ettik.",
    text3:
      "Konforlu ahşap teknelerle Ege sahillerini gezin; sevimli tatil kasabalarını, eski medeniyetleri ve eşsiz doğal güzellikleri keşfedin.",
    text4: "Tursab Numarası",
    text5: "Ege mutfağını deneyimleyin",
    text6: "Antik Anadolu medeniyetlerini keşfedin",
    text7: "Eşsiz doğal güzelliklere tanık olun",
    text8: "Akdeniz ritmini hissedin",
    text9: "Uluslararası",
    text10:
      "Deneyimli tatil danışmanlarımız haftanın 7 günü size yardımcı olmak için sorularınızı bekliyorlar.",
    text11: "Neden Biz?",
    text12:
      "Deneyimli tatil danışmanlarımız haftanın 7 günü size yardımcı olmak için sorularınızı bekliyorlar.",
    text13: "Güvenli Rezervasyon",
    text14: "Doğru Fiyat Politikası",
    text15: "Tutkulu Hizmet",
    text16: "Sailing & People bir SIESTA YATCILIK A.Ş. markasıdır.",
    text17: (e) => {
      return `Haftalık €${e}'dan başlayan fiyatlarla`;
    },
    text19: "Gulet",
    text20: "Günlük",
    text21: "Haftalık",
    text22: "Detayları göster",
    text23: "Sıralama yöntemi",
    text24: "Varsayılan Sıralama...",
    text25: "Kamara",
    text26: "Misafir",
    text27: "Temizle",
    text28: "Uygula",
    text29: "Fiyata göre artan",
    text30: "Fiyata göre azalan",
    text31: "Filtreleri iptal et",
    text32: "Tekne Tipi",
    text33: "Yelkenli",
    text34: "Katamaran",
    text35: "Daha fazla",
    text36: "Bir hata oluştu",
    text37: "Geri Git",
    text38: "Fatura bilgilerini düzenle",
    text39: "Profili Düzenle",
    text40: "Rezervasyonlarınız",
    text41: "Şifre Değiştir",
    text42: "Yüklemeye çalıştığınız resimin boyutu çok büyük!",
    text43: "Fatura Bilgileriniz",
    text44: "Bu alan zorunludur!",
    text45: "Değişiklikleri kaydet",
    text46: "Seçiniz...",
    text47: "Kadın",
    text48: "Erkek",
    text49: "Beklenmedik bir hata oluştu",
    text50: "Genel bilgileriniz",
    text51: "İsim",
    text52: "Soyisim",
    text53: "Doğum tarihi",
    text54: "Cinsiyet",
    text55: "E-posta adresi",
    text56: "Telefon numarası",
    text57: "Girdiğiniz numara 16 karakterten fazla olamaz!",
    text58: "Girdiğiniz numara 10 karakterten az olamaz!",
    text59: "Yaşadığınız yer",
    text60: "Favori Tekneleriniz",
    text61:
      "Hesabınızda favorilere eklemiş olduğunuz bir tekne bulunmamaktadır.",
    text62: "Resim Bulunamadı...",
    text63: "Rezervasyonlarınız",
    text64: "Hiç bir rezervasyonunuz bulunmamaktadır.",
    text65:
      "Rezervasyonunuz alınmıştır, en kısa sürede sizinle irtibata geçilecektir.",
    text66: "Rezervasyonunuz onaylanmıştır.",
    text67: "Satış işlemi tamamlanmıştır.",
    text68: "Tekne Adı",
    text69: "Tekne Tipi",
    text70: "Başlangıç",
    text71: "Bitiş",
    text72: "Kabin Sayısı",
    text73: "Misafir Sayısı",
    text74: "Boy",
    text75: "En",
    text76: "Durum",
    text77: "Tarih",
    text78: "Kabin / Misafir Sayısı",
    text79: "metre",
    text80: "Yeni şifre en az 6 karakter olmalıdır!",
    text81: "Yeni şifre 20 karakterden fazla olamaz!",
    text82: "Onay şifresi aynı olmalı!",
    text83: "Tekrar Hoşgeldiniz",
    text84: "Lütfen hesabınıza giriş yapın",
    text85: "Hesabınız yok mu?",
    text86: "Hemen Kaydolun!",
    text87: "Şifremi unuttum!",
    text88: "Lütfen geçerli bir e-posta adresi girin!",
    text89: "Bu alan gereklidir!",
    text90: "Şifre en az 6 karakterden oluşmalıdır!",
    text91: "Şifre 20 karakterden uzun olmamalıdır!",
    text92: "Hoşgeldiniz",
    text93: "Hesabınızı kurtarmak için e-posta adresinizi giriniz.",
    text94: "Bu alan zorunludur!",
    text95: "Lütfen geçerli bir mail adresi giriniz.",
    text96: "Tekrar mail gönder",
    text97: "Mail gönder",
    text98: "Giriş ekranına dön",
    text99: "cerez_politikasi",
    text100: "Çerez Politikası",
    text101: "musteri_aydinlatma_metni",
    text102: "Müşteri aydınlatma metni",
    text103: "Kayıt Ol",
    text104: "Çerez Politikasını kabul ediyorum",
    text105: "Lütfen geçerli bir telefon numarası girin!",
    text106: "Şifre",
    text107: "Şifre uyuşmuyor!",
    text108:
      "Şirketler, özel gruplar için uzman eğitmenler eşliğinde yoga ve pilates gezileri ile hem denizin ve güneşin tadını hem de yoganın ve pilatesin iç huzurunu birlikte yaşayın.",
    text109: "Yoga & Pilates",
    text110: "Bisiklet ve Trekking",
    text111:
      "Sahillerimizin eşsiz tabiatında size özel trekking ve bisiklet turlarıyla doğanın keyfini dünya cenneti koylarda yaşayın.",
    text112: "Evlilik ve Balayı",
    text113:
      "Düğün merasimlerinizi veya balayınızı unutulmaz kılmak için tekne organizasyonlarımızla ilgili mutlaka bizi arayın.",
    text114: "Mutfak Workshopları",
    text115:
      "İster pastacılık ister italyan mutfağı... Uzman eğitmenler eşliğinde özel gruplar için mutfak workshoplarımızla yeteneklerinizi geliştirin.",
    text116: "Tematik Geziler",
    text117: "Tematik gezileriniz için mutlaka bizimle iletişime geçin.",
    text118: "Yoga ve Pilates",
    text119: "Mutfak ve Workshopları",
    text120: "Evlilik ve Balayı",
    text121: "Detayları Görüntüle",
    text124: "SİZ TATİLİNİZİN TADINI ÇIKARIN, GERİSİNİ BİZE BIRAKIN!",
    text125:
      "Size özel rotanızı, en ince detayına kadar yemek menülerinizi, kumanya alışverişlerinizi seyahat danışmanlırımız ile birlikte planlayacak, tatil gününüz geldiğinde teknenize elinizi kolunuzu sallayarak bineceksiniz.",
    text126: "İNCELE",
    text127:
      "Sailing & People, Türkiye Sahillerindeki en doğru tekneleri, en doğru rota ile, en doğru kiralama fiyatlarıyla bulabileceğiniz tekne kiralama platformunuz.",
    text128: "Doğru Tekne",
    text129: "Doğru Fiyat",
    text130: "Kişisel İletişim",
    text131: "Denizde Özgürlük ve Keyif: YELKENLİ",
    text132: "Mavi Yolculuk Klasiği : GULET",
    text133: "Denizde Konforun Zirvesi: KATAMARAN",
    text135: "Hepsini göster",
    text138: "En Favoriler : GULET",
    text139: "Tekne Seçenekleri",
    text140: "Tekneni Kirala & Maviliklere Açıl!",
    text141: "Ara",
    text142: "Örnek Rotalarımız",
    text143: "Özel rota talepleriniz için lütfen iletişime geçiniz.",
    text144: "Hisarönü Körfezi",
    text145: "Marmaris-Göcek",
    text146: "Giriş Yap",
    text147: "Tüm Tekneler",
    text148: "Sıkça Sorulan Sorular",
    text149: "Ana Sayfa",
    text150: "Profili Düzenle",
    text151: "Çıkış Yap",
    text152: "Tekne Kodu",
    text153: "Profil ayarları",
    text154: "Aradığınız kriterlerde gemi bulamadık...",
    text155: "Doğru tekne nedir?",
    text156:
      "Tüm bakımları tam ve eksiksiz yapılmış, turizm bakanlığı denetiminde turizm işletme belgesi olan teknelerdir.",
    text157: "Doğru fiyat nedir?",
    text158:
      "Piyasa koşulları içinde kalite ve fiyat buluşmasında asla aldanmayacağınız fiyatlardır.",
    text159: "Doğru rota nedir?",
    text160:
      "Teknenizle denize açıldığınız ilk noktadan itibaren öncelikle deneyimli kaptanları ile, mevcut hava koşullarına göre düzenlenmiş,deniz ve güneşten en iyi şekilde faydalanmanızı sağlarken,sizleri yormadan  en keyifli koyları ziyaret edebilmenizi imkan tanıyan güzergahtır.",
    text161: "Tutkulu hizmet nedir?",
    text162:
      "Sailing&People dan tekne kiralayanlar  'Müşteri' olarak değil  'Misafir' olarak nitelenirler. Misafirlerimizin %100 memnuniyeti ana felsefemizdir. Bu nedenle işimizi tutkuyla yaparız.",
    text163: "Kişisel iletişim nedir?",
    text164:
      "Sailing &People platformunda tekne seçiminden,rotanız,yemek menüleriniz,kumanya alışverişiniz ve tüm özel talepleriniz için size özel seyahat danışmanımıza  özel mobil telefon hattından ulaşabilirsiniz.Size sunmuş olduğumuz deneyimli  kişisel seyahat danışman hizmetimiz diğer platformlardan en önemli farklarımızdan biridir.",
    text165: "Yardım Merkezi",
    text166:
      "Rezervasyonlarınız, hesabınız ve çok daha fazlasıyla ilgili yardım alın.",
    text167: "Gulet",
    text168: "Sıkça Sorulan Sorular",
    text169: "Bu konu hakkında sıkça sorulan soru bulunmamaktadır.",
    text170: "10:00 Turgut Köyün'de teknede buluşma.",
    text171:
      "Tekne tanıtımı ve oryantasyon, yolculuk planlanması, kumanya yüklenmesi",
    text172: "12:00 Marinadan ayrılma,ortalama 2 saat yelken seyri",
    text173:
      "16:00 Kuz Bükün'e varış, yüzme ve akşam yemeği(Neighbours Restaurant)",
    text174: "07:00 Sabah yogası , yüzme ve teknede kahvaltı",
    text175: "11:00 Kuz Bükü'den ayrılma , hedef koya yelken seyri",
    text176: "Alargada yüzme molası ve öğle yemeği.",
    text177:
      "16:00 Dirsek Bükü’ne varış. Alargada konaklama ve teknede akşam yemeği.",
    text178: "07:00 Sabah yogası, yüzme ve teknede kahvaltı",
    text179:
      "13:00 Dirsek Bükü ’nden ayrılış.Hedef koya yelken seyri, alarga ve teknede öğle yemeği.",
    text180: "17:00 Bozburun Yacht Club’a varış. Club’ta akşam yemeği.",
    text181:
      "07:00 Sabah yogası, yüzme ve teknede kahvaltı , doğa yürüyüşü ve Bozburun Kasabasını gezme.",
    text182:
      "13:00 Bozburundan ayrılma, Söğüt Koyun'na yelken seyri, alarga ve teknede öğle yemeği.",
    text183:
      "17:00Söğüt Koyuna varış. Alargada konaklama ve teknede akşam yemeği.",
    text184: "07:00 Sabah yogası, yüzme ve teknede kahvaltı",
    text185:
      "13:00 Söğüt Koyu ’ndan ayrılış.Hedef koya yelken seyri, alarga ve teknede öğle yemeği.",
    text186:
      "17:00 Bozukkale ‘ye varış. Alargada konaklama akşam yemeği ve canlı müzik. (Loryma Club).",
    text187:
      "07:00 Yüzme ve teknede kahvaltı. Doğa yürüyüşü (hayvanları ziyaret; keçi,eşek,kaplumbağa...)",
    text188:
      "13:00 Bozukkale'den ayrılış Hedef koya yelken seyri , alarga ve teknede öğle yemeği.",
    text189:
      "17:00Emel Sayın Koyu'na varış. Alargada konaklama ve teknede akşam yemeği.",
    text190: "07:00 Sabah yogası, yüzme ve teknede kahvaltı.",
    text191:
      "12:00 Emel Sayın Koyu ’ndan ayrılış.Selimiye'ye yelken seyri, Selimiye'de Serbest zaman.",
    text192: "16:00 Selimiye'den ayrılma",
    text193: "17:00 Turgut Zakkum Limana varış. Veda Kokteyli.",
    text194: "Cuma",
    text195: "Perşembe",
    text196: "Çarşamba",
    text197: "Salı",
    text198: "Pazartesi",
    text199: "Pazar",
    text200: "Cumartesi",
    text201: "Bodrum Ege Rotaları",
    text202:
      "Rotalar üzerinde videolu tanıtım filmleri için lokasyonların üzerine tıklayınız.",
    text203: "10:00 Netsel Marina'da buluşma",
    text204:
      "Tekne tanıtımı ve oryantasyon, yolculuk planlaması, kumanya yüklemesi.",
    text205: "12:00 Marinadan ayrılma, ortalama 2 saat yelken seyri",
    text206:
      "16:00 Çiftlik Koyuna varış, yüzme ve akşam yemeği(Azmak Restaurant)",
    text207: "Rodos'a varış",
    text208: "07:00 Sabah Yogası , yüzme ve teknede kahvaltı",
    text209: "10:00 Çiftlik'ten ayrılma, ortalama 3 saat yelken seyri",
    text210: "Alarga da yüzme molası ve öğle yemeği",
    text211: "16:00 Ekincik'e varış, akşam yemeği(My Marina)",
    text212: "09:00 Ekincik'ten ayrılma.Dalyan Kaunos turu(isteğe bağlı)",
    text213: "16:00 Dalyan'dan ayrılış, ortalama 2 saat yelken seyri",
    text214: "18:00 Baba Adasına varış/alarga da akşam yemeği",
    text215: "10:00 Baba Adasından ayrılma , ortalama 3 saat yelken seyri",
    text216: "13:00 Göcek Göbün Koyu öğle molası; dinlenme,yüzme",
    text217: "17:00 Göbün'de teknede konaklama",
    text218: "10:00 Fethiye Turunç Pınarı'ndan ayrılma, 1.5 saat yelken seyri",
    text219:
      "12:00 Bedri-Rahmi koyunda alarga/öğle yemeği(restaurant mevcuttur Olive)yüzme molası",
    text220: "16:00 Hamam Koyu Adaia tesisi/akşam yemeği,eğlence(DJ)",
    text221:
      "10:00 Adaia'dan ayrılma, Uzun yol yapılan tek gün ortalama 6 saat Yelken seyri",
    text222:
      "15:00 Ekincik koyun'a varış/alarga , yüzme , dinlenme, akşam yemeği",
    text223: "09:00 Ekincik'ten ayrılma,yelken seyri",
    text224: "12:00 Kumlubük'te öğle yemeği,yüzme",
    text225: "14:00 Kumlubük'ten Marmaris'e hareket",
    text226: "17:00 Netsel Marina Bağlanma, veda kokteyli",
    text227: "Marmaris Rodos Rotaları",
    text228:
      "Rotalar üzerinde videolu tanıtım filmleri için lokasyonların üzerine tıklayınız.",
    text229: "İmkanlar",
    text230: "Fiyat",
    text231: "Bu tekne hakkında bir fiyat belirtilmemiştir.",
    text232: "Genişlik",
    text233: "Tipi",
    text234: "Kaptanlı",
    text235: "Kaptansız",
    text236: "kişi",
    text237: "oda",
    text238: "Lokasyon",
    text239: "Talep oluştur",
    text240: "Zaman aralığı",
    text241:
      "Seyahatler Cumartesi’den Cumartesi’ye planlanmaktadır. Farklı taleplerinizi, seyahat danışmanınıza iletebilirsiniz.",
    text242: "Kapasite",
    text243:
      "Bu tekne için misafir ve kamara kapasitesi yanda belirtilmiştir. Farklı kamara ve misafir adedi taleplerinizi ayrıca burada belirtebilirsiniz.",
    text244:
      "Rota, kumanya ve benzeri tüm konularla ilgili özel taleplerinizi bu alana yazabilirsiniz.",
    text245: "Talep Oluştur",
    text246: "Talep oluşturmak için lütfen giriş yapınız.",
    text247: "Şuan ödeme yapmayacaksınız",
    text248: "Gün",
    text249: "Neden Biz?",
    text250: "Yorum",
    text251: "Yorum bırak",
    text252: "İnsanlara tecrübe ettiklerini anlat...",
    text253: "Gönder",
    text254: "Genel",
    text255: "Yorumlar",
    text256: "Fotoğrafları görüntüle",
    text257: "İşlem sırasında bir hata meydana geldi.",
    text258: "Ana sayfaya geri dön",
    text259: "Mailiniz onaylanmıştır",
    text260: "Sayfayı kapatabilirsiniz.",
    text261: (firstName, lastName) => {
      return `Sayın ${firstName} ${lastName}, ön rezervasyon talebiniz başarıyla alınmıştır. Seyahat danışmanımız en hızlı şekilde size geri dönüş sağlayacaktır.`;
    },
    text262: "Ana sayfaya dön",
    text263: "İşlem başarılı!",
    text264: "İşlem sırasında bir hata meydana geldi!",
    text265: "Gulet ile Mavi Yolculuk:",
    text266: "SORUNUZ MU VAR ?",
    text267: "Kamara ve Misafir belirle",
    text268: "İmkanlar",
    text269: "Yorumu silmek istediğinizden emin misiniz?",
    text270: "Ülke",
    text271: "Şehir",
    text272: "Semt/İlçe",
    text273: "Posta Kodu",
    text274: "Adres",
    text275: "Yeni şifre",
    text276: "Yeni şifre onay",
    text277: "Şifre onay",
    text278: "Kabul et",
    text279: "Sailing & People'a Hoşgeldiniz",
    text280: "Lütfen hesabınız için kaydolun",
    text281: "Zaten hesabınız var mı!",
    text282: "Yelkenli/Katmaran",
    text283: "Uzunluk",
    text284: "Kaptan Tipi",
    text285: "Yorum bulanamadı",
    text286: "Puan",
    text287: "Rezervasyon Talebiniz Oluşturuldu",
    // text288: "Rezervasyon İçin",
    text289: "Bu tekneyle ilgileniyorum, lütfen benimle iletişime geçin",
    text290: "Tarih aralığı",
    text291: "İlgileniyorum, lütfen benimle iletişime geçin",
    text292: "Teknik Özellikler",
    text293: "Metre",
    text294: "Notlar",
    text295: "Yukarıda belirtilen fiyatlara KDV dahil değildir.",
    text296: "Yukarıda belirtilen fiyatlara yiyecek/içecek dahil değildir.",
    text297: "Fiyatlara tekne, liman ücretleri, kullanma suyu dahildir.",
    text298: "Klima en geç gece yarısı saat 01:00 de kapatılmalıdır.",
    text299: "Tekneye biniş saati: Öğleden sonra 16:00",
    text300: "Tekneden iniş saati: Sabah 09:00",
    text301: "Yeme&İçme:",
    text302: "Hazırlanacak menü'ye göre market alışverişini, sizin adınıza yapılabiliriz.",
    text303: "Ödemeyi tekneye bindiğinizde kredi kartınızla market elemanına yapabilirsiniz",
    text304: "Alkollü içeceklerinizi yanınızda getirebilirsiniz.",
    text305: "Veya;  Yemek ücreti kişi başı/günlük 70 Euro'dur (içecekler ekstradır)",
    text306: "Veya;  Yemek ücreti kişi başı/günlük 110 Euro'dur (yerli içecekler dahildir)"
  },
};

export default languageData;
