// General Page Section
export const HOME_PAGE = "/";
export const AGENTS_PAGE = "/agents";

// Listing Single Page Section
export const LISTING_POSTS_PAGE = "/listing";
export const SINGLE_POST_PAGE = "/post";

// Agent Profile Page Section
export const AGENT_PROFILE_PAGE = "/profile";
export const AGENT_ACCOUNT_SETTINGS_PAGE = "/account-settings";
export const AGENT_PROFILE_EDIT_PAGE = "/edit";
export const AGENT_IMAGE_EDIT_PAGE = "/change-image";
export const AGENT_BILLING_EDIT_PAGE = "/change-billing";
export const AGENT_PASSWORD_CHANGE_PAGE = "/change-password";
export const AGENT_LISTING_PAGE = "/listing";
export const AGENT_FAVOURITE_PAGE = "/favourite-post";
export const AGENT_PROFILE_DELETE = "/delete";
export const AGENT_PROFILE_FAVOURITE = "/favourite-post";
export const AGENT_PROFILE_CONTACT = "/contact";

// Other Pages
export const PRICING_PLAN_PAGE = "/yardim-merkezi";
export const HELP_DETAILS = "/yardim-merkezi/detaylar";
export const ADD_HOTEL_PAGE = "/add-hotel";
export const THANK_YOU_PAGE = "/confirm-mail";
export const THANK_YOU_RESERVATION = "/thank-you";
export const CONFIRM = "/confirm-mail-redirect";
export const CONCEPT_TRAVELS = "/concept";
export const ROUTES_EGE = "/routes/ege";
export const ROUTES_MARMARIS = "/routes/marmaris";
// Login / Registration Page
export const LOGIN_PAGE = "/sign-in";
export const REGISTRATION_PAGE = "/sign-up";
export const CHANGE_PASSWORD_PAGE = "/change-password";
export const FORGET_PASSWORD_PAGE = "/forget-password";
